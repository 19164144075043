import { ReactNode } from 'react';
import { DeltaValue, TriggerValue } from 'types/alerters';
import { BatteryState } from 'types/device';

export const Comparator = {
  CHANGE: 'change',
  CONFIRMED_CHANGE: 'confirmed_change',
  EQUAL: 'eq',
  NOT_EQUAL: 'neq',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  DELTA_GREATER_THAN: 'delta_gt',
  DELTA_LESS_THAN: 'delta_lt',
  EVENT: 'event',
  INSIDE: 'in',
  OUTSIDE: 'nin',
} as const;
export type Comparator = (typeof Comparator)[keyof typeof Comparator];

export type AlternativeComparatorLabel = Partial<Record<Comparator, string>>;

export interface TriggerType {
  value: string;
  label: string;
  comparatorOptions: Comparator[];
  additionalOptions?: {
    label: string;
    value: string;
    comparator: string;
    comparatorValue: boolean | string;
  }[];
  defaults?: Partial<Record<string, number>>;
  defaultRule: string;
  unit?: string;
  withValue?: boolean;
  min?: number;
  max?: number;
  deltaMin?: number;
  deltaMax?: number;
  step?: number;
  transformValue?: (value: number) => number;
  transformOnFetch?: (value: number) => number;
  adminOnly?: boolean;
  description?: string;
  alternativeComparatorLabels?: AlternativeComparatorLabel;
}

export const deviceTriggerTypes: TriggerType[] = [
  {
    value: 'device_connected',
    label: 'Connection Status',
    comparatorOptions: [Comparator.CHANGE, Comparator.EQUAL],
    additionalOptions: [
      {
        value: 'connect',
        label: 'Connected',
        comparator: Comparator.EQUAL,
        comparatorValue: true,
      },
      {
        value: 'disconnect',
        label: 'Disconnected',
        comparator: Comparator.EQUAL,
        comparatorValue: false,
      },
    ],
    defaultRule: Comparator.CHANGE,
    // description: 'Description text',
  },
  {
    value: 'device_configured',
    label: 'Config. Status',
    comparatorOptions: [Comparator.CHANGE, Comparator.EQUAL],
    additionalOptions: [
      {
        value: 'configured',
        label: 'Configured',
        comparator: Comparator.EQUAL,
        comparatorValue: true,
      },
      {
        value: 'disconfigured',
        label: 'Misconfigured',
        comparator: Comparator.EQUAL,
        comparatorValue: false,
      },
    ],
    defaultRule: Comparator.CHANGE,
  },
  {
    value: 'worn_status',
    label: 'Worn Status',
    comparatorOptions: [
      Comparator.CHANGE,
      Comparator.EQUAL,
      Comparator.NOT_EQUAL,
    ],
    additionalOptions: [
      {
        value: 'worn',
        label: 'Worn',
        comparator: Comparator.EQUAL,
        comparatorValue: true,
      },
      {
        value: 'not_worn',
        label: 'Not worn',
        comparator: Comparator.EQUAL,
        comparatorValue: false,
      },
      {
        value: 'un_worn',
        label: 'Worn or unknown',
        comparator: Comparator.NOT_EQUAL,
        comparatorValue: false,
      },
      {
        value: 'un_not_worn',
        label: 'Not worn or unknown',
        comparator: Comparator.EQUAL,
        comparatorValue: false,
      },
    ],
    defaultRule: Comparator.CHANGE,
  },
  {
    value: 'battery_level',
    label: 'Battery Level',
    comparatorOptions: [Comparator.GREATER_THAN, Comparator.LESS_THAN],
    defaultRule: Comparator.LESS_THAN,
    unit: '%',
    defaults: {
      [Comparator.LESS_THAN]: 30,
      [Comparator.GREATER_THAN]: 90,
    },
    min: 0,
    max: 100,
    transformValue: (n: number) => n / 100,
    transformOnFetch: (n: number) => n * 100,
  },
  {
    value: 'battery_charging',
    label: 'Battery Charging',
    comparatorOptions: [Comparator.CHANGE, Comparator.EQUAL],
    additionalOptions: [
      {
        comparator: Comparator.EQUAL,
        comparatorValue: false,
        label: 'Is not charging',
        value: 'not_charging',
      },
      {
        comparator: Comparator.EQUAL,
        comparatorValue: true,
        label: 'Is charging',
        value: 'charging',
      },
    ],
    defaultRule: Comparator.CHANGE,
  },
  {
    value: 'battery_state',
    label: 'Battery Status',
    comparatorOptions: [Comparator.CHANGE, Comparator.EQUAL],
    defaultRule: Comparator.CHANGE,
    additionalOptions: [
      {
        comparator: Comparator.EQUAL,
        comparatorValue: BatteryState.FULL,
        value: 'battery_full',
        label: 'Full',
      },
      {
        comparator: Comparator.EQUAL,
        comparatorValue: BatteryState.CHARGING,
        value: 'battery_charging',
        label: 'Charging',
      },
      {
        comparator: Comparator.EQUAL,
        comparatorValue: BatteryState.UNPLUGGED,
        value: 'battery_unplugged',
        label: 'Unplugged',
      },
    ],
  },
  {
    value: 'test_connection',
    label: 'Connection Test',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
    adminOnly: true,
  },
  {
    value: 'water_lock',
    label: 'Water Lock',
    comparatorOptions: [
      Comparator.CHANGE,
      Comparator.EQUAL,
      Comparator.NOT_EQUAL,
    ],
    additionalOptions: [
      {
        value: 'enabled',
        label: 'Enabled',
        comparator: Comparator.EQUAL,
        comparatorValue: true,
      },
      {
        value: 'not_disabled',
        label: 'Enabled or unknown',
        comparator: Comparator.NOT_EQUAL,
        comparatorValue: false,
      },
      {
        value: 'disabled',
        label: 'Disabled',
        comparator: Comparator.EQUAL,
        comparatorValue: false,
      },
    ],
    defaultRule: Comparator.CHANGE,
  },
  {
    value: 'data_heartbeat',
    label: 'Data Heartbeat',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
    adminOnly: true,
  },
];

export const healthTriggerTypes: TriggerType[] = [
  {
    value: 'heart_rate',
    label: 'Heart Rate',
    comparatorOptions: [
      Comparator.GREATER_THAN,
      Comparator.LESS_THAN,
      Comparator.DELTA_GREATER_THAN,
      Comparator.DELTA_LESS_THAN,
    ],
    defaultRule: Comparator.LESS_THAN,
    min: 30,
    max: 200,
    deltaMin: 5,
    deltaMax: 100,
    transformOnFetch: (n: number | DeltaValue) => {
      if (typeof n === 'number') {
        return n;
      }
      return n?.delta;
    },
    unit: 'BPM',
  },
  {
    value: 'apple_high_heart_rate_alert',
    label: 'Persistent High Heart Rate',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
  },
  {
    value: 'apple_low_heart_rate_alert',
    label: 'Persistent Low Heart Rate',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
  },
  {
    value: 'apple_irregular_heart_rhythm_alert',
    label: 'A-fib Detection',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
  },
  {
    value: 'blood_oxygen',
    label: 'Blood Oxygen',
    comparatorOptions: [Comparator.LESS_THAN],
    defaultRule: Comparator.LESS_THAN,
    min: 0,
    max: 100,
    // transformValue: (n: number) => n / 100,
    // transformOnFetch: (n: number) => n * 100,
    unit: '%',
  },
  {
    value: 'tonic_clonic_motion_score',
    label: 'Seizure-like Motion Confidence',
    min: 70,
    max: 100,
    step: 1,
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    transformValue: (n: number) => Number((n / 100).toFixed(2)),
    transformOnFetch: (n: number) => Math.round(n * 100),
    unit: '%',
  },
  {
    value: 'perseverative_motion_score',
    label: 'Perseverative Motion Confidence',
    min: 70,
    max: 100,
    step: 1,
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    transformValue: (n: number) => Number((n / 100).toFixed(2)),
    transformOnFetch: (n: number) => Math.round(n * 100),
    unit: '%',
  },
  {
    value: 'fall',
    label: 'Fall Detection',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
  },
  {
    value: 'help_requested',
    label: 'Help Request',
    comparatorOptions: [Comparator.EVENT],
    defaultRule: Comparator.EVENT,
  },
];

export const locationTriggerTypes: TriggerType[] = [
  {
    value: 'safe_zone',
    label: 'Safe Zone',
    comparatorOptions: [
      Comparator.CHANGE,
      Comparator.CONFIRMED_CHANGE,
      Comparator.EQUAL,
      Comparator.INSIDE,
      Comparator.OUTSIDE,
    ],
    defaultRule: Comparator.CHANGE,
  },
  {
    value: 'distance_covered',
    label: 'Travel Distance',
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    defaults: {
      [Comparator.GREATER_THAN]: 100,
    },
    min: 100,
    unit: 'meters',
  },
  {
    value: 'speed',
    label: 'Speed',
    comparatorOptions: [Comparator.GREATER_THAN, Comparator.LESS_THAN],
    defaultRule: Comparator.LESS_THAN,
    defaults: {
      [Comparator.LESS_THAN]: 2,
      [Comparator.GREATER_THAN]: 3,
    },
    min: 0,
    max: 100,
    transformValue: (value) => value / 1.609,
    transformOnFetch: (value) => value * 1.609,
    unit: 'MPH',
  },
  {
    value: 'emergency_tracking',
    label: 'Emergency Tracking',
    comparatorOptions: [Comparator.CHANGE],
    defaultRule: Comparator.CHANGE,
  },
];

export const activityTriggerTypes: TriggerType[] = [
  {
    value: 'daily_step_count',
    label: 'Daily Step Count',
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    defaults: {
      [Comparator.GREATER_THAN]: 1000,
    },
    min: 0,
    unit: 'Steps',
  },
  {
    value: 'daily_calorie_burn',
    label: 'Daily Calorie Burn',
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    defaults: {
      [Comparator.GREATER_THAN]: 1000,
    },
    min: 0,
    unit: 'Cal',
  },
  {
    value: 'daily_sleep_time',
    label: 'Daily Sleep Time',
    comparatorOptions: [Comparator.GREATER_THAN],
    defaultRule: Comparator.GREATER_THAN,
    defaults: {
      [Comparator.GREATER_THAN]: 28800,
    },
    transformOnFetch: (n: number) => n * 60,
    transformValue: (n: number) => Math.round(n / 60),
    min: 0,
  },
];

export const allTriggerTypes = [
  ...deviceTriggerTypes,
  ...healthTriggerTypes,
  ...activityTriggerTypes,
  ...locationTriggerTypes,
];

export const additionalOptions: Record<
  string,
  { comparator: Comparator; value: boolean | string }
> = {
  worn: {
    comparator: Comparator.EQUAL,
    value: true,
  },
  not_worn: {
    comparator: Comparator.EQUAL,
    value: false,
  },
  un_worn: {
    comparator: Comparator.NOT_EQUAL,
    value: false,
  },
  un_not_worn: {
    comparator: Comparator.NOT_EQUAL,
    value: false,
  },
  configured: {
    comparator: Comparator.EQUAL,
    value: true,
  },
  disconfigured: {
    comparator: Comparator.EQUAL,
    value: false,
  },
  charging: {
    comparator: Comparator.EQUAL,
    value: true,
  },
  not_charging: {
    comparator: Comparator.EQUAL,
    value: false,
  },
  battery_charging: {
    comparator: Comparator.EQUAL,
    value: BatteryState.CHARGING,
  },
  battery_unplugged: {
    comparator: Comparator.EQUAL,
    value: BatteryState.UNPLUGGED,
  },
  battery_full: {
    comparator: Comparator.EQUAL,
    value: BatteryState.FULL,
  },
  enabled: {
    comparator: Comparator.EQUAL,
    value: true,
  },
  disabled: {
    comparator: Comparator.EQUAL,
    value: false,
  },
  not_disabled: {
    comparator: Comparator.NOT_EQUAL,
    value: false,
  },
};

const transformAdditionalOption = (
  option: string,
): { comparator: Comparator; value: number | boolean | string } => {
  additionalOptions[option];
  return additionalOptions[option];
};

export const getAdditionalOption = ({
  comparator,
  value,
  metric,
}: {
  comparator: string;
  value?: TriggerValue;
  metric: string;
}): string => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const triggerType = allTriggerTypes.find((t) => t.value === metric)!;

  return (
    triggerType.additionalOptions?.find(
      (opt) => opt.comparatorValue === value && opt.comparator === comparator,
    )?.value ?? 'change'
  );
};

export const getComparatorWithValue = ({
  comparator,
  value,
  zones,
  baseline,
}: {
  comparator: string | Comparator;
  value?: number | string;
  zones?: number[] | null;
  baseline?: number;
}): {
  comparator: Comparator | string;
  value?: TriggerValue;
} => {
  if (zones || zones === null) {
    return {
      comparator,
      value: zones,
    };
  }

  if (isRuleDelta(comparator as string)) {
    return {
      comparator,
      value: {
        delta: value as number,
        lookback_window_size: baseline ?? 1800,
      },
    };
  }

  if (Object.values(Comparator).includes(comparator as Comparator)) {
    return {
      comparator,
      value,
    };
  }

  return transformAdditionalOption(comparator);
};

export const getHumanFriendlyComparator = ({
  comparator,
  value,
  metric,
}: {
  comparator: Comparator | string;
  value?: TriggerValue;
  metric: string;
}): {
  comparator: Comparator | string;
  value?: TriggerValue;
} => {
  if (['boolean', 'string'].includes(typeof value)) {
    return { comparator: getAdditionalOption({ comparator, value, metric }) };
  }

  if (comparator === Comparator.CHANGE && value === null) {
    return { comparator };
  }

  return {
    comparator,
    value,
  };
};

export const normalizeTriggerValue = (
  value: number,
  min?: number,
  max?: number,
) => {
  if (typeof min !== 'undefined' && value < min) {
    return min;
  }

  if (typeof max !== 'undefined' && value > max) {
    return max;
  }

  return value;
};

export const getTriggerGroup = (triggerType: string) => {
  if (deviceTriggerTypes.find((t) => t.value === triggerType)) {
    return 'device';
  }

  if (healthTriggerTypes.find((t) => t.value === triggerType)) {
    return 'health';
  }

  if (activityTriggerTypes.find((t) => t.value === triggerType)) {
    return 'activity';
  }

  if (locationTriggerTypes.find((t) => t.value === triggerType)) {
    return 'location';
  }

  return 'uncategorized';
};

export const formatNumericMetricValue = (
  value: number,
  triggerType: string,
) => {
  switch (triggerType) {
    case 'battery_level':
      return value * 100;
    case 'speed':
      return value * 1.609;
    default:
      return value;
  }
};

export const formatMetricValue = (
  value: TriggerValue,
  triggerType: string,
  comparator: string,
  withoutPrefix?: boolean,
): {
  value: ReactNode;
  unit: string;
} => {
  const type = allTriggerTypes.find((t) => t.value === triggerType);

  if (type?.transformOnFetch) {
    return {
      value: type
        .transformOnFetch(value as number)
        .toFixed(type.unit === '%' ? 0 : 2),
      unit: type.unit ?? '',
    };
  }

  if (typeof value === 'number') {
    const prefix = withoutPrefix
      ? ''
      : comparator === Comparator.LESS_THAN
      ? '< '
      : comparator === Comparator.GREATER_THAN
      ? '> '
      : '';

    return {
      value: prefix + formatNumericMetricValue(value, triggerType),
      unit: type?.unit ?? '',
    };
  }

  if (typeof value === 'boolean') {
    const additionalOption = type?.additionalOptions?.find(
      (opt) => opt.comparatorValue === value && opt.comparator === comparator,
    );

    return {
      value: additionalOption?.label ?? (value ? 'Yes' : 'No'),
      unit: '',
    };
  }

  if (value === null) {
    if (comparator === Comparator.EVENT) {
      return {
        value: 'Event',
        unit: '',
      };
    }
    return {
      value: 'On change',
      unit: '',
    };
  }

  if (typeof value === 'string') {
    return {
      value,
      unit: '',
    };
  }

  if ('delta' in value) {
    const prefix = comparator === Comparator.DELTA_LESS_THAN ? '< ' : '> ';
    return {
      value: `Delta ${prefix} ${value.delta} in the last ${Math.floor(
        value.lookback_window_size / 60,
      )} minutes`,
      unit: '',
    };
  }

  return {
    value,
    unit: '',
  };
};

export const adminOnlyTriggerTypes = allTriggerTypes
  .filter((t) => t.adminOnly)
  .map((t) => t.value);

export const triggerDescriptions: Record<string, string> =
  allTriggerTypes.reduce((acc, t) => {
    if (t.description) {
      acc[t.value] = t.description;
    }

    return acc;
  }, {} as Record<string, string>);

export const isRuleDelta = (rule: string) =>
  rule === Comparator.DELTA_GREATER_THAN || rule === Comparator.DELTA_LESS_THAN;
